const index = document.querySelector("#index");
const services = document.querySelector("#services");
const contact = document.querySelector("#contact");

const navBar = document.querySelector("nav");
const header = document.querySelector("header");
const optNav = {
  rootMargin="-10%"
};
const navigationObs = new IntersectionObserver((entries, navigationObs) => {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) {
      navBar.classList.add("nav-scrolled");
    } else {
      navBar.classList.remove("nav-scrolled");
    }
  });
}, optNav);
navigationObs.observe(header);


if (index || services) {
  const squares = document.querySelectorAll(".square");
  const optTitle = {
    threshold: 0,
    rootMargin: "-17%",
  };
  const appearOnScroll = new IntersectionObserver((entries, appearOnScroll) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      } else {
        const lines = entry.target.getElementsByClassName("line");
        Object.values(lines).forEach((line) => {
          if (line.className === "line line-01") {
            line.classList.add("animate-width");
          }
          if (line.className === "line line-02") {
            line.classList.add("animate-height");
          }
          if (line.className === "line line-03") {
            line.classList.add("animate-width");
          }
          if (line.className === "line line-04") {
            line.classList.add("animate-height");
          }
        });
        appearOnScroll.unobserve(entry.target);
      }
    });
  }, optTitle);

  squares.forEach((square) => {
    appearOnScroll.observe(square);
  });
}
if(services){
  const cardsContainer = document.querySelector(".card-1");
  const cardsContainer2 = document.querySelector(".card-2");
  const optCard = {
    threshold: 0,
    rootMargin: "-17%",
  };
  const onCardScroll = new IntersectionObserver(
    (entries, onCardScroll)=>{
      entries.forEach(entry=>{
        if (!entry.isIntersecting) {
          return;
        } else {
          const cards=document.getElementsByClassName('card');
          Object.values(cards).forEach((card,i)=>{
            setTimeout(()=>{
              card.classList.add("card-show")
            }, i * 550);
          })
        }
      })
    }, optCard);
    onCardScroll.observe(cardsContainer);
}
if(contact){
  console.log("this is the contact section");
  // form validation
  const form = document.getElementById("contactForm");
  const formHoney = document.getElementById("mainName");
  const name = document.getElementById("name");
  const email = document.getElementById("email");
  const message = document.getElementById("message");
  const checkBox = document.getElementById("invalidCheck");
  const formResponse = document.getElementById("formResponse");
  form.addEventListener("submit", (e) => {
    e.preventDefault();
    // Escape if the honeypot has been filled
    console.log(formHoney.value);
    if (!!formHoney.value) {
      return;
    }
    checkinputs();
    const successForm = document.querySelectorAll(".success");
    if (successForm.length === 4) {
      console.log("form valid");
      getFormData();
    }
  });

  const checkinputs = () => {
    const nameValue = name.value.trim();
    const emailValue = email.value.trim();
    const messageValue = message.value.trim();
    const checkValue = checkBox.checked;

    if (nameValue === "") {
      setErrorFor(name, "Name is required.");
    } else if (nameValue.length < 4) {
      setErrorFor(name, "Name must be longer than 4 characters.");
    } else {
      setSuccessFor(name);
    }
    if (emailValue === "") {
      setErrorFor(email, "Email is required.");
    } else if (!isEmail(emailValue)) {
      setErrorFor(email, "Email is not valid.");
    } else {
      setSuccessFor(email);
    }
    if (messageValue === "") {
      setErrorFor(message, "Message is required.");
    } else if (messageValue.length < 30) {
      setErrorFor(message, "Message must be longer than 30 characters.");
    } else {
      setSuccessFor(message);
    }
    if (checkValue === false) {
      setErrorFor(checkBox, "Please agree to terms & conditions.");
    } else {
      setSuccessFor(checkBox);
    }
  };
  const setErrorFor = (input, message) => {
    const formGroupName = input.parentElement;
    const small = formGroupName.querySelector("small");
    formGroupName.className = "form-group error";
    small.innerText = message;
  };
  const setSuccessFor = (input) => {
    const formGroupName = input.parentElement;
    formGroupName.className = "form-group success";
  };
  const isEmail = (email) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  };
  const getFormData = () => {
    const data = {};
    const formElements = Array.from(form);
    formElements.splice(4, 2);
    formElements.map((input) => (data[input.name] = input.value));
    console.log(JSON.stringify(data));

    // Construct an HTTP request
    var xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action, true);
    xhr.setRequestHeader("Accept", "application/json; charset=utf-8");
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");

    // Send the collected data as JSON
    xhr.send(JSON.stringify(data));

    // Callback function
    xhr.onloadend = (response) => {
      if (response.target.status === 200) {
        // The form submission was successful
        form.reset();
        formResponse.className = "success";
        formResponse.innerHTML =
          "Thanks for the message. I’ll be in touch shortly.";
        setTimeout(() => {
          formResponse.style.visibility = "hidden";
        }, 3000);
      } else {
        // The form submission failed
        formResponse.innerHTML =
          "Something went wrong. Please try again later or give us a call.";
        formResponse.className = "error";
        setTimeout(() => {
          formResponse.style.visibility = "hidden";
        }, 3000);
        console.error(JSON.parse(response.target.response).message);
      }
    };
  };
}